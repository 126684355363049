import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo";
import Layout from "../components/common/layout/layout";
import SubblockPageHeader from "../components/common/subblock/subblock-page-header";
import SubblockHeadlineWithSubtitle from "../components/common/subblock/subblock-headline-with-subtilte";
import SubblockList from "../components/common/subblock/subblock-list";

const UeberUns = ({ data }) => {
  const header = data.strapiFuerAerzte.header
  const title = data.strapiFuerAerzte.title
  const subTitle = data.strapiFuerAerzte.subTitle
  const downloadList = data.strapiFuerAerzte.downloadList


  return (
    <>
      <Seo title="Füer Ärzte und Ärztinnen" />
      <Layout>
        <SubblockPageHeader content={header} />
        <SubblockHeadlineWithSubtitle title={title} subTitle={subTitle} noBottom />
        <SubblockList list={downloadList} fullwidth download/>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
query strapiFuerAerzteUndAerztinnenQuery {
  strapiFuerAerzte {
    header {
      ...fragmentHeader
    }
    title
    subTitle
    downloadList {
      title
      download {
        localFile {
          publicURL
        }
      }
    }
  }
}
`

export default UeberUns
